import { makeRequest } from './requestBuilder'

export const API = {
  get: (url , body, headers, bodyparams) =>
    makeRequest({
      method: 'get',
      url ,
      body,
      headers,
      bodyparams,

    }),
  getfile: (url, body, headers, bodyparams, responseType) =>
    makeRequest({
      method: 'get',
      url,
      body,
      headers,
      bodyparams,
      responseType
    }),


  post: (url, body, headers, bodyparams) =>
    makeRequest({
      method: 'post',
      url,
      body,
      headers,
      bodyparams,
    }),
  postFile: (url, body, headers, bodyparams, responseType) =>
    makeRequest({
      method: 'post',
      url,
      body,
      headers,
      bodyparams,
      responseType
    }),
  patch: (url, body) =>
    makeRequest({
      method: 'patch',
      body,
      url
    }),

  put: (url, body) =>
    makeRequest({
      method: 'put',
      body,
      url
    }),

  delete: url =>
    makeRequest({
      method: 'delete',
      url
    })


}