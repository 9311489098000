import Slider from "react-slick";
import { useEffect, useState, useRef, useReducer } from "react";
import Odometer from "react-odometerjs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { getCars, getContactInfo, sendadminemail } from "../services/carsSlice";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useNavigate } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

function Inventory() {
  const { enqueueSnackbar } = useSnackbar();
  const [cardata, setcardata] = useState([]);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };
  const [oricardata, setoricardata] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: "(max-width: 1224px)" });
  const { cargetdetails } = useSelector((state) => ({
    ...state.carsSlice,
  }));
  const reducer = (state, { field, value }) => {
    return { ...state, [field]: value };
  };
  const navigate = useNavigate();
  const apidispatch = useDispatch();
  useEffect(() => {
    getCarsInvList();
  }, []);
  const [formState, dispatch] = useReducer(
    reducer,
    {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      message: "",
      make: "",
      model: "",
      miles: "",
      year: "",
      cartype: "",
      price: "",
    },
    undefined
  );
  const {
    fname,
    lname,
    email,
    phone,
    message,
    make,
    model,
    miles,
    year,
    cartype,
    price,
  } = formState;

  const handleInput = (e) => {
    dispatch({
      field: Object.keys(e.target.dataset)[0],
      value: e.target.value,
    });
  };
  const getCarsInvList = () => {
    setOpen(true);
    apidispatch(getCars())
      .then((values) => {
        let result = values?.payload?.data?.result;
        let filtered = _.filter(result, function (o) {
          return o.carstatus !== "Sold";
        });

        let sorted_array = _.orderBy(filtered, ["updatedAt"], ["asc"]);
        setcardata(sorted_array);

        setoricardata(sorted_array);
        setOpen(false);
      })
      .catch((error) => {});
  };

  const handleSubmit = (e,item) => {
    setOpen(true);
    var data = JSON.parse(JSON.stringify(formState));
    data.car = item;
   
    if (phone && fname  && email) {
    apidispatch(sendadminemail(data))
      .then(async (values) => {
        let result = values?.payload?.data?.result;
        setOpen(false);
        enqueueSnackbar(cargetdetails?.emailtoast);
        document.getElementById("eform").reset();
        document.getElementById('closeModal').click();
      })
      .catch((error) => {
        setOpen(false);
      });
    } else {
      e.preventDefault();
      enqueueSnackbar(cargetdetails?.emailtoast);
      setOpen(false);
    }
  };

  const handleSearch = (e, item) => {
    let filterdata = _.clone(oricardata);
    let filtereddata = [];
    switch (item) {
      case "make":
        filtereddata = filterdata.filter((obj) =>
          obj.make
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );
        break;
      case "model":
        filtereddata = _.filter(filterdata, function (obj) {
          return obj.model.toLowerCase().indexOf(e.target.value) !== -1;
        });
        break;
      case "miles":
        filtereddata = filterdata.filter(
          (obj) => parseFloat(obj.miles) <= parseFloat(e.target.value)
        );

        break;
      case "year":
        filtereddata = filterdata.filter(
          (obj) => parseFloat(obj.year) <= parseFloat(e.target.value)
        );
        break;
      case "cartype":
        filtereddata = filterdata.filter((obj) =>
          obj.cartype
            .toString()
            .toLowerCase()
            .includes(e.target.value.toLowerCase())
        );

        break;

      case "price":
        filtereddata = filterdata.filter(
          (obj) => parseFloat(obj.price) <= parseFloat(e.target.value)
        );

        break;

      default:
        setcardata(filterdata);
    }
    if (e.target.value.length !== 0) {
      setcardata(filtereddata);
    } else {
      setcardata(filterdata);
    }
  };

  return (
    <>
      <div class="page_wrapper">
        <Header></Header>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <main class="page_content">
          <section
            class="page_banner"
            style={{
              backgroundImage: 'url("assets/images/shapes/tyre_print_5.svg")',
            }}
          >
            <div class="container">
              <ul class="breadcrumb_nav unordered_list">
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/details">Inventory</a>
                </li>
              </ul>
              <h3 class="page_title wow" data-splitting>
                Inventory
              </h3>
            </div>
          </section>
          {!isTabletOrMobile && (
            <section class="product_section mt-5 pt-0">
              <div class="container">
                <form action="#">
                  <div class="product_filter_inline row">
                    <div class="col-lg-2 col-md-6 col-sm-6">
                      <select
                        type="text"
                        onInput={handleInput}
                        onChange={(e) => handleSearch(e, "year")}
                        data-year
                        class="form-select mb-0"
                        placeholder="YEAR"
                      >
                        <option value="" selected>
                          YEAR
                        </option>

                        <option label="2000 Below" value="2000">
                          2000 Below
                        </option>
                       
                        <option label="2010 Below" value="2010">
                          2010 Below
                        </option>
                        <option label="2015 Below" value="2015">
                          2015 Below
                        </option>
                        <option label="2015 Below" value="2020">
                        2020 Below
                        </option>
                       
                        <option label="2024 Below" value="2024">
                          2024 Below
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6">
                      <select
                        class="form-select mb-0"
                        data-make
                        onInput={handleInput}
                        onChange={(e) => handleSearch(e, "make")}
                        placeholder="MAKE"
                      >
                        <option value="" selected>
                          MAKE
                        </option>
                        {cargetdetails &&
                          cargetdetails?.make.map((make, index) => (
                            <option key={index} value={make}>
                              {make}
                            </option>
                          ))}
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6">
                      <select
                        type="text"
                        onInput={handleInput}
                        onChange={(e) => handleSearch(e, "miles")}
                        data-miles
                        class="form-select mb-0"
                        placeholder="MILES"
                      >
                        <option value="" selected>
                          Miles
                        </option>

                      
                     
                        <option label="50,000 Below" value="50000">
                          50,000 Below
                        </option>
                   
                        <option label="100,000 Below" value="100000">
                          100,000 Below
                        </option>
                        <option label="150,000 Below" value="150000">
                          150,000 Below
                        </option>
                        <option label="200,000 Below " value="200000">
                          200,000 Below{" "}
                        </option>
                      </select>
                    </div>

                    <div class="col-lg-2 col-md-6 col-sm-6">
                      <select
                        class="form-select mb-0"
                        defaultValue={"Car Type"}
                        onInput={handleInput}
                        onChange={(e) => handleSearch(e, "cartype")}
                        placeholder="Model"
                      >
                        <option value="" selected disabled hidden>
                          Model
                        </option>
                        {cargetdetails &&
                          cargetdetails?.cartype.map((cartype, index) => (
                            <option key={index} value={cartype}>
                              {cartype}
                            </option>
                          ))}
                      </select>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-6">
                      <select
                        class="form-select mb-0"
                        data-price
                        onInput={handleInput}
                        onChange={(e) => handleSearch(e, "price")}
                        placeholder="PRICE"
                      >
                        <option value="" selected>
                          Price
                        </option>

                        <option label="$5,000 Below" value="5000">
                          $5,000  Below
                        </option>
                     
                        <option label="$10,000 Below" value="10000">
                          $10,000  Below
                        </option>
                      
                        <option label="$15,000 Below" value="15000">
                          $15,000  Below
                        </option>
                        <option label="$20,000 Below" value="20000">
                          $20,000  Below
                        </option>
                       
                        <option label="$50,000 Below" value="50000">
                          $50,000  Below
                        </option>
                      </select>
                    </div>
                  </div>
                </form>
              </div>
            </section>
          )}

          <section class="shop_section pb-0">
            <div class="container">
              <div class="row">
                {cardata.length !== 0 &&
                  cardata.map((item, index) => (
                    <>
                      <div
                        class="modal cargetModal"
                        id={"enquireModal" + index}
                      >
                        <form id="eform"  onSubmit={(e)=>{e.preventDefault();handleSubmit(e,item)}}>
                          <div class="modal-dialog">
                            <div class="modal-content">
                              <div class="modal-header">
                                <h4 class="modal-title">Enquire</h4>
                                <button
                                  type="button"
                                  class="btn-close"
                                  data-bs-dismiss="modal"
                                ></button>
                              </div>

                              <div class="modal-body">
                                <p class="mb-4">
                                  I am interested in:{" "}
                                  <b>
                                    {item.year +
                                      " " +
                                      item.make +
                                      " " +
                                      item.model}
                                  </b>
                                </p>

                                <div class="form_wrap row">
                                  <div class="col-md-6">
                                    <div class="form-group mb-0">
                                      <label for="input_name">First Name</label>
                                      <input
                                        type="text"
                                        onInput={handleInput}
                                        data-fname
                                        class="form-control"
                                        placeholder="Enter First Name"
                                        required
                                      ></input>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group mb-0">
                                      <label for="input_phone">last Name</label>
                                      <input
                                        type="text"
                                        onInput={handleInput}
                                        data-lname
                                        class="form-control"
                                        placeholder="Enter Last Name"
                                        required
                                      ></input>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group mb-0">
                                      <label for="input_phone">E Mail</label>
                                      <input
                                        type="text"
                                        onInput={handleInput}
                                        data-email
                                        class="form-control"
                                        placeholder="Enter Email Id"
                                        required
                                      ></input>
                                    </div>
                                  </div>
                                  <div class="col-md-6">
                                    <div class="form-group mb-0">
                                      <label for="input_phone">
                                        Contact Number
                                      </label>
                                      <input
                                        type="text"
                                        onInput={handleInput}
                                        data-phone
                                        class="form-control"
                                        placeholder="Enter Phone Number"
                                        required
                                      ></input>
                                    </div>
                                  </div>

                                  <div class="col-12">
                                    <div class="form-group">
                                      <label for="input_textarea">
                                        Your Message
                                      </label>
                                      <textarea
                                        data-message
                                        onInput={handleInput}
                                        class="form-control"
                                        id="input_textarea"
                                        placeholder="Tell us More"
                                      ></textarea>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div class="modal-footer">
                                <div class="flex-spread">
                                  <a
                                    class="btn-link mr-3"
                                    href="#"
                                    data-bs-toggle="modal"
                                    data-bs-target="#enquireModal"
                                  >
                                    <span class="btn_icon">
                                      <i class="fa-regular fa-angle-right"></i>
                                    </span>
                                    <span
                                      class="btn_text"
                                      data-bs-dismiss="modal"
                                      id="closeModal"
                                    >
                                      <small>Cancel</small>
                                      <small>Cancel</small>
                                    </span>
                                  </a>
                                  <button
                                    type="submit"
                                    class="btn btn-danger"
                                    
                                  
                                  >
                                    Enquire
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                      <div class="col-lg-3 col-md-6 col-sm-6" key={index}>
                        <div class="product_item">
                          <a
                            class="item_image"
                            href={"/invdetails?vin=" + item?.vin}
                          >
                            <img src={item?.s3url} alt=" Product Image"></img>
                          </a>
                          <div class="item_content">
                            <h3 class="item_title">
                              <a href={"/invdetails?vin=" + item?.vin}>
                                {item.year + " " + item.make + " " + item.model}
                              </a>
                            </h3>
                            <a
                              class="item_brand"
                              href={"/invdetails?vin=" + item?.vin}
                            >
                              Miles: {item.miles}
                            </a>
                            <div class="item_footer">
                              <div class="item_price price_block">
                                <span class="sale_price">
                                  {"$" + item.price}
                                </span>
                              </div>
                              <a
                                class="btn-link"
                                href="#"
                                data-bs-toggle="modal"
                                data-bs-target={"#enquireModal" + index}
                              >
                                <span class="btn_icon">
                                  <i class="fa-regular fa-angle-right"></i>
                                </span>
                                <span class="btn_text">
                                  <small>Enquire</small>
                                  <small>Enquire</small>
                                </span>
                              </a>
                            </div>
                          </div>
                          <ul class="cart_btns_group unordered_list_block">
                            {/* <li>
                              <a href="javacript:void(0);">
                                <i class="fa-light fa-heart"></i>
                              </a>
                            </li>
                            <li>
                              <a href={"/invdetails?vin=" + item?.vin}>
                                <i class="fa-light fa-eye"></i>
                              </a>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </>
                  ))}
              </div>
              {/* <div class="products-controls mt-3 mb-3">
                <div class="select-group">
                  <label class="me-3">Show</label>
                  <select class="form-select mb-0">
                    <option selected>10</option>
                    <option value="1">20</option>
                    <option value="2">50</option>
                    <option value="3">100</option>
                  </select>
                </div>
                <div class="pagination_wrap pt-0">
                  <ul class="pagination_nav unordered_list">
                    <li>
                      <a href="#!">
                        <i class="fa-regular fa-angle-left"></i>
                      </a>
                    </li>
                    <li class="active">
                      <a href="#!">1</a>
                    </li>
                    <li>
                      <a href="#!">2</a>
                    </li>
                    <li>
                      <a href="#!">3</a>
                    </li>
                    <li>
                      <a href="#!">
                        <i class="fa-regular fa-angle-right"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div> */}
            </div>
          </section>
        </main>

        <Footer></Footer>
      </div>
    </>
  );
}

export default Inventory;
