import Slider from "react-slick";
import { useEffect, useState, useRef, useReducer } from "react";
import Odometer from "react-odometerjs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import {
  getCars,
  getgooglereviews,
  sendadminemail,
} from "../services/carsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { SnackbarProvider, VariantType, useSnackbar } from 'notistack';

function Main() {
  const { enqueueSnackbar } = useSnackbar();
  const [sliderRef, setSliderRef] = useState(null);
  const [value, setValue] = useState(0);
  const [custvalue, setcustvalue] = useState(0);
  const [cardata, setcardata] = useState([]);
  const { cargetdetails } = useSelector((state) => ({
    ...state.carsSlice,
  }));
  const apidispatch = useDispatch();
  const { reviews } = useSelector((state) => ({
    ...state.carsSlice,
  }));
  const [carreviews, setreviews] = useState(reviews || []);

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  useEffect(() => {
    getCarsList();
    if (carreviews.length === 0) {
      getReviews();
    }
    const timeoutId = setTimeout(() => setValue(500), 2000);
    const custtimeoutId = setTimeout(() => setcustvalue(100), 2000);
    return () => {
      clearTimeout(timeoutId);
      clearTimeout(custtimeoutId);
    };
  }, []);
  const navigate = useNavigate();
  const reducer = (state, { field, value }) => {
    return { ...state, [field]: value };
  };
  const [formState, dispatch] = useReducer(
    reducer,
    {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      message: "",
    },
    undefined
  );
  const { fname, lname, email, phone, message } = formState;

  const handleInput = (e) => {
    dispatch({
      field: Object.keys(e.target.dataset)[0],
      value: e.target.value,
    });
  };

  const getCarsList = () => {
    setOpen(true);
    apidispatch(getCars())
      .then((values) => {
        let result = values?.payload?.data?.result;
        let filtered = _.filter(result, function (o) {
          return o.carstatus !== "Sold";
        });

        let sorted_array = _.orderBy(filtered, ["createdAt"], ["desc"]);
        setcardata(_.take(sorted_array, 4));
        setOpen(false);
      })
      .catch((error) => {});
  };

  const getReviews = () => {
    setOpen(true);
    apidispatch(getgooglereviews())
      .then((values) => {
        let result = values?.payload?.data?.result;
       
        setreviews(result?.reviews);
        setOpen(false);
      })
      .catch((error) => {});
  };

  const handleSubmit = (e) => {
    
    setOpen(true);
    var data = JSON.parse(JSON.stringify(formState));

    if (phone && fname  && email) {
      apidispatch(sendadminemail(data))
        .then(async (values) => {
          let result = values?.payload?.data?.result;
      
          enqueueSnackbar(cargetdetails?.emailtoast);
          document.getElementById("eform").reset();
          setOpen(false);
        })
        .catch((error) => {
       
          setOpen(false);
        });
    } else {
      e.preventDefault();
      setOpen(false);
    }
  };

  const sliderSettings = {
    // removes default buttons

    dots: false,
    speed: 1000,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    pauseOnHover: true,
    autoplaySpeed: 5000,

    responsive: [
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const mainslidersettings = {
    dots: false,
    fade: true,
    speed: 1000,
    arrows: false,
    infinite: true,
    autoplay: true,
    slidesToShow: 1,
    autoplaySpeed: 3000,
  };

  return (
    <>
   
      <div className="page_wrapper">
        <Header></Header>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={open}
          onClick={handleClose}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <main className="page_content">
          {/* Main Slider - Start
      ================================================== */}
          <section className="main_slider_section main_slider_3">
            <Slider {...mainslidersettings} className="main_slider">
              <div>
                <div
                  className="slider_item"
                  style={{
                    backgroundImage:
                      'url("assets/images/hero/slider_bg_image_1.jpg")',
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="slider_content">
                          <img
                            src="assets/images/site_logo/cartget_logo_full_white.svg"
                            className="hero_logo"
                            data-animation="fadeInUp"
                            data-delay=".0s"
                          />
                          <h3
                            className="title_text wow"
                            data-animation="fadeInUp"
                            data-delay=".2s"
                          >
                            {cargetdetails && cargetdetails.carbanner}
                          </h3>

                          <div
                            className="btn_wrap p-0"
                            data-animation="fadeInUp"
                            data-delay=".6s"
                          >
                            <a className="btn btn-primary" href="/details">
                              <span className="btn_text">
                                Explore Our Inventory
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className="slider_item"
                  style={{
                    backgroundImage:
                      'url("assets/images/hero/slider_bg_image_2.jpg")',
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="slider_content">
                          <h3
                            className="title_text wow"
                            data-animation="fadeInUp"
                            data-delay=".2s"
                          >
                            {cargetdetails && cargetdetails.carbanner2}
                          </h3>
                          <p
                            className="wow animated"
                            data-animation="fadeInUp"
                            data-delay=".4s"
                            style={{ visibility: "visible" }}
                          >
                            Browse Our Premium Used Car Collection for Style,
                            Reliability, and Affordability."
                          </p>
                          <div
                            className="btn_wrap p-0"
                            data-animation="fadeInUp"
                            data-delay=".6s"
                          >
                            <a className="btn btn-primary" href="/details">
                              <span className="btn_text">
                                Explore Our Inventory
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div
                  className="slider_item"
                  style={{
                    backgroundImage:
                      'url("assets/images/hero/slider_bg_image_3.jpg")',
                  }}
                >
                  <div className="container">
                    <div className="row align-items-center">
                      <div className="col-lg-6">
                        <div className="slider_content">
                          <h3
                            className="title_text wow"
                            data-animation="fadeInUp"
                            data-delay=".2s"
                          >
                            {cargetdetails && cargetdetails.carbanner3}
                          </h3>
                          <p
                            className="wow animated"
                            data-animation="fadeInUp"
                            data-delay=".4s"
                            style={{ visibility: "visible" }}
                          >
                            Trust{" "}
                            <span className="carget-text">
                              Car<span>get</span>
                            </span>{" "}
                            for a Seamless and Trusted Car Buying Experience."
                          </p>
                          <div
                            className="btn_wrap p-0"
                            data-animation="fadeInUp"
                            data-delay=".6s"
                          >
                            <a className="btn btn-primary" href="/details">
                              <span className="btn_text">
                                Explore Our Inventory
                              </span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Slider>
          </section>

          {/* Main Slider - End
      ================================================== */}

          <section class="find_car_section">
            <div class="container">
              <form  id="eform" onSubmit={(e)=>{e.preventDefault();handleSubmit(e)}}>
                <div class="tab_boxed bg_gray_dark">
                  <div class="tab-content m-0">
                    <div class="section_heading">
                      <h3 class="heading_text wow" data-splitting>
                        Let's find your perfect car
                      </h3>
                    </div>
                    <div class="form_wrap row">
                      <div class="col-md-3">
                        <div class="form-group mb-0">
                          <label for="input_name">Your Name</label>
                          <input
                            type="text"
                            onInput={handleInput}
                            data-fname
                            class="form-control"
                            placeholder="Enter Name"
                            required
                          ></input>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group mb-0">
                          <label for="input_phone">Your Phone</label>
                          <input
                            type="text"
                            onInput={handleInput}
                            data-phone
                            class="form-control"
                            placeholder="Enter Phone Number"
                            required
                          ></input>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group mb-0">
                          <label for="input_date">Car Looking For</label>
                          <input
                            type="text"
                            onInput={handleInput}
                            data-email
                            class="form-control"
                            placeholder="Enter Car Looking For"
                            required
                          ></input>
                        </div>
                      </div>
                      <div class="col-md-3">
                        <div class="form-group mb-0">
                          <label for="input_time">Message if Any</label>
                          <input
                            type="text"
                            onInput={handleInput}
                            data-message
                            class="form-control"
                            placeholder="Enter Message if Any"
                            required
                          ></input>
                        </div>
                      </div>
                      <div class="col-12">
                        <button type="submit" class="btn btn-primary">
                          <span class="btn_text" >
                            Enquire
                          </span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </section>

          <section class="about_section section_space_sm">
            <div class="container">
              <div class="row align-items-center justify-content-lg-between">
                <div class="order-lg-last col-lg-8">
                  <img
                    src="assets/images/common-images/welcome-graphic.png"
                    alt=" Welcome Graphic"
                    class="img-fluid"
                  ></img>
                </div>
                <div class="col-lg-4">
                  <div class="about_content">
                    <div class="section_heading">
                      <h5 class="heading_text wow" data-splitting>
                        Welcome to Car<span class="logotext">get</span>
                      </h5>
                      <h5>U.S.A’s Best Choice for Used Cars</h5>
                      <p class="heading_description mb-0">
                        At{" "}
                        <span class="carget-text">
                          {" "}
                          Car<span class="red-text">Get</span>
                        </span>
                        , we understand that finding the perfect used car can be
                        an exciting yet daunting task. That's why we're here to
                        make the process seamless, transparent, and enjoyable
                        for you.
                      </p>
                      <a class="btn-link mt-4" href="/about">
                        <span class="btn_icon">
                          <i class="fa-regular fa-angle-right"></i>
                        </span>
                        <span class="btn_text">
                          <small>More Info</small>
                          <small>More Info</small>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="product_section pb-0">
            <div class="container">
              <div class="section_heading">
                <div class="row align-items-center">
                  <div class="col-lg-8">
                    <h2 class="heading_text mb-0 wow" data-splitting>
                      Explore Our latest Inclusions
                    </h2>
                  </div>
                  <div class="col-lg-4 d-none d-lg-flex justify-content-end">
                    <a class="btn btn-primary" href="/details">
                      <span class="btn_text">View All</span>
                    </a>
                  </div>
                </div>
              </div>

              <div class="row">
                {cardata.length !== 0 &&
                  cardata.map((item, index) => (
                    <div class="col-lg-3 col-md-6 col-sm-6" key={index}>
                      <div class="product_item">
                        <a
                          class="item_image"
                          href={"/invdetails?vin=" + item?.vin}
                        >
                          <img src={item?.s3url} alt="Car Image"></img>
                        </a>
                        <div class="item_content">
                          <h3 class="item_title">
                            <a href={"/invdetails?vin=" + item?.vin}>
                              {item.year + " " + item.make + " " + item.model}{" "}
                            </a>
                          </h3>
                          <a
                            class="item_brand"
                            href={"/invdetails?vin=" + item?.vin}
                          >
                            Miles: {item.miles}
                          </a>
                          <div class="item_footer">
                            <div class="item_price price_block">
                              <span class="sale_price">{"$" + item.price}</span>
                            </div>
                          </div>
                        </div>
                        <ul class="cart_btns_group unordered_list_block">
                          {/* <li>
                            <a href="javacript:void(0);">
                              <i class="fa-light fa-heart"></i>
                            </a>
                          </li> 
                          <li>
                            <a href={"/invdetails?vin=" + item?.vin}>
                              <i class="fa-light fa-eye"></i>
                            </a>
                          </li>*/}
                        </ul>
                      </div>
                    </div>
                  ))}
              </div>

              <div class="btn_wrap text-center d-lg-none d-block">
                <a class="btn btn-primary" href="/details">
                  <span class="btn_text">View All</span>
                </a>
              </div>
            </div>
          </section>

          <section class="workprocess_section section_space_lg">
            <div class="container">
              <div class="row justify-content-lg-between">
                <div class="col-lg-4">
                  <div class="section_heading">
                    <h2 class="heading_text wow mb-lg-0" data-splitting>
                      Why Choose car<span class="logotext">get</span>?
                    </h2>

                    <p class="heading_description mb-0 d-lg-none">
                      Fringilla urna porttitor rhoncus dolor purus non enim
                      praesent. Faucibus nisl tincidunt eget nullam non nisi est
                      sit amet. Morbi blandit cursus risus at. Sit amet
                      facilisis magna etiam. Sit amet venenatis urna cursus
                    </p>
                  </div>
                  <div class="workprocess_item">
                    <h3 class="item_title">
                      <span class="serial_number">01</span>
                      <span class="title_text">Quality Assurance</span>
                    </h3>
                    <p class="mb-0">
                      Every vehicle in our inventory undergoes a rigorous
                      inspection process to ensure it meets the highest
                      standards of quality and reliability.
                    </p>
                  </div>
                  <div class="workprocess_item">
                    <h3 class="item_title">
                      <span class="serial_number">02</span>
                      <span class="title_text">Diverse Inventory</span>
                    </h3>
                    <p class="mb-0">
                      Whether you're looking for a sleek sedan, a spacious SUV,
                      or a reliable compact car, we have a diverse inventory to
                      match your needs
                    </p>
                  </div>
                  <div class="workprocess_item">
                    <h3 class="item_title">
                      <span class="serial_number">03</span>
                      <span class="title_text">Transparent Pricing</span>
                    </h3>
                    <p class="mb-0">
                      Our straightforward pricing ensures you get a fair deal
                      without any hidden fees or surprises.
                    </p>
                  </div>
                </div>
                <div class="col-lg-7">
                  <div class="section_heading">
                    <p class="heading_description d-none d-lg-block">
                      Our commitment to customer satisfaction goes beyond the
                      sale. We're here to build long-lasting relationships with
                      our clients. Our friendly and knowledgeable staff is ready
                      to assist you at every step of your car-buying journey.
                    </p>
                    <div class="funfact_wrapper bg_primary">
                      <div class="funfact_item d-flex">
                        <div class="counter_value">
                          <Odometer class="odometer" value={value}></Odometer>
                          <span>+</span>
                        </div>
                        <p class="counter_description mb-0">Happy Clients</p>
                      </div>
                      <div class="funfact_item d-flex">
                        <div class="counter_value">
                          <Odometer class="odometer" value={custvalue}>
                            0
                          </Odometer>
                          <span>%</span>
                        </div>
                        <p class="counter_description mb-0">
                          Customer Satisfaction
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="image_widget">
                    <img
                      src="assets/images/common-images/happyCustomer.jpg"
                      alt="About Image"
                    ></img>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section class="testimonial_section pb-0 mb-5">
            <div class="container">
              <div class="section_heading">
                <div class="row align-items-center">
                  <div class="col-lg-6">
                    <h2 class="heading_text mb-0 wow" data-splitting>
                      Recent Review & Rating
                    </h2>
                  </div>
                  <div class="col-lg-6 d-none d-lg-flex justify-content-end">
                    <a class="btn btn-primary"  target="_blank"
                          rel="noreferrer" href="https://www.google.com/search?kgmid=/g/11vf1c23x5&hl=en-IN&q=CARGET+LLC&kgs=14ee9c41b6cb717c&shndl=17&source=sh/x/kp/osrp/m5/1#lrd=0x88051bae3a084feb:0xa386621e85babd74,1,,,,">
                      <span class="btn_text">View All</span>
                    </a>
                  </div>
                  {/* <div class="col-lg-6 d-none d-lg-flex justify-content-end">
                    
                    <ul class="carousel_arrow unordered_list">
                      <li>
                        <button
                          type="button"
                          class="c3c_arrow_left"
                          onClick={sliderRef?.slickNext}
                        >
                          <i class="fa-regular fa-angle-left"></i>
                          <i class="fa-regular fa-angle-left"></i>
                        </button>
                      </li>
                      <li>
                        <button
                          type="button"
                          class="c3c_arrow_right"
                          onClick={sliderRef?.slickPrev}
                        >
                          <i class="fa-regular fa-angle-right"></i>
                          <i class="fa-regular fa-angle-right"></i>
                        </button>
                      </li>
                      
                    </ul>
                  </div> */}
                </div>
              </div>

              <div class="testimonial_carousel">
                <Slider
                  ref={setSliderRef}
                  {...sliderSettings}
                  class="testimonial_item_boxed carousel_3col row"
                  data-slick='{"dots": false}'
                >
                  {carreviews &&
                    carreviews.map((slide, index) => (
                      <div class="col-" key={index}>
                        <div class="testimonial_item border-0">
                          <h3 class="item_title">
                            <span class="quote_icon">
                              <i class="fa-solid fa-quote-right"></i>
                            </span>
                            <span class="title_text">
                              {slide.relative_time_description}
                            </span>
                          </h3>
                          <p class="testimonial_content mb-0">{slide.text}</p>
                          <div class="admin_wrap">
                            <h4 class="admin_name">{slide.author_name}</h4>
                            <ul class="rating_star unordered_list">
                              <li>
                                <i class="fa-solid fa-star"></i>
                              </li>
                              <li>
                                <i class="fa-solid fa-star"></i>
                              </li>
                              <li>
                                <i class="fa-solid fa-star"></i>
                              </li>
                              <li>
                                <i class="fa-solid fa-star"></i>
                              </li>
                              <li>
                                <i class="fa-solid fa-star"></i>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                </Slider>
              </div>
              {/* 
            <div class="btn_wrap pb-0 text-center d-lg-none d-block">
              <ul class="carousel_arrow unordered_list_center">
                <li>
                  <button type="button" onClick={sliderRef?.slickNext} class="c3c_arrow_left">
                    <i class="fa-regular fa-angle-left"></i>
                    <i class="fa-regular fa-angle-left"></i>
                  </button>
                </li>
                <li>
                  <button onClick={sliderRef?.slickPrev} type="button" class="c3c_arrow_right">
                    <i class="fa-regular fa-angle-right"></i>
                    <i class="fa-regular fa-angle-right"></i>
                  </button>
                </li>
              </ul>
            </div> */}
             <div class="btn_wrap text-center d-lg-none d-block">
                <a class="btn btn-primary"  target="_blank"
                          rel="noreferrer" href="https://www.google.com/search?kgmid=/g/11vf1c23x5&hl=en-IN&q=CARGET+LLC&kgs=14ee9c41b6cb717c&shndl=17&source=sh/x/kp/osrp/m5/1#lrd=0x88051bae3a084feb:0xa386621e85babd74,1,,,,">
                  <span class="btn_text">View All</span>
                </a>
              </div>
            </div>
          </section>

          <section class="video_section">
            <div
              class="video_wrap parallaxie text-center section_space_lg"
              style={{
                backgroundImage:
                  'url("assets/images/common-images/landing-parallax.jpg")',
              }}
            >
              <div class="container">
                <div class="row justify-content-center">
                  <div class="col-lg-6">
                    <div class="section_heading">
                      <h2 class="heading_text wow" data-splitting>
                        Buy used cars with confidence
                      </h2>
                      <p class="heading_description mb-0 ps-lg-5 pe-lg-5">
                        At{" "}
                        <span class="carget-text">
                          Car<span>get</span>
                        </span>
                        , we're not just selling cars; we're helping you embark
                        on your next adventure with confidence. Drive with peace
                        of mind – choose{" "}
                        <span class="carget-text">
                          Car<span>get</span>
                        </span>{" "}
                        for your used car needs.
                      </p>
                    </div>
                    <a class="btn btn-primary" href="/details">
                      <span class="btn_text">View Our Inventory</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>

        <Footer></Footer>
      </div>
    </>
  );
}

export default Main;
