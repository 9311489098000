import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import Odometer from 'react-odometerjs'
import Header from "../components/Header";
import Footer from "../components/Footer";
function About() {
 
    const [value, setValue] = useState(0);
    const [custvalue, setcustvalue] = useState(0);
    useEffect(() => {
        const timeoutId = setTimeout(() => setValue(500), 2000);
        const custtimeoutId = setTimeout(() => setcustvalue(100), (2000));
        return () => {
            clearTimeout(timeoutId);
            clearTimeout(custtimeoutId);
        };
    }, []);



  return (
   
     
      <>
      
 
    
  
    <div class="page_wrapper">
  
   
   <Header></Header>
   <main class="page_content">


      <section class="page_banner" style={{  backgroundImage: "url('assets/images/shapes/tyre_print_3.svg')"}}>
        <div class="container">
          <ul class="breadcrumb_nav unordered_list">
            <li><a href="/">Home</a></li>
            <li><a href="/about">About Us</a></li>
          </ul>
          <h3 class="page_title wow" data-splitting>About US</h3>
        </div>
      </section>
    
      <section class="about_section section_space_sm">
        <div class="container">
          <div class="row align-items-center justify-content-lg-between">
            <div class="col-lg-6">
              <div class="image_widget">
                <img src="assets/images/about/about_image_10.jpg" alt="ProMotors - About Image"></img>
              </div>
            </div>
            <div class="col-lg-5">
              <div class="about_content">
                <div class="section_heading">
                  <h2 class="heading_text wow" data-splitting>
                    Welcome to Car<span class="logotext">get</span>
                  </h2>
                  <p class="heading_description mb-5">
                    Your Trusted Partner in Quality Used Cars!
                  </p>
                  <p class="heading_description mb-0">
                    At <span class="carget-text">Car<span>get</span></span>, we understand that buying a used car is not just a transaction; it's a significant decision that impacts your daily life. That's why we are dedicated to providing a seamless and transparent car-buying experience that you can trust.
                  </p>
                </div>
                <div class="row">
                  <div class="col-md-6">
                    <div class="counter_item">
                      <div class="counter_value">
                      <Odometer class="odometer" value={value}></Odometer>
                        <span>+</span>
                        
                      </div>
                      <hr class="bg_primary"></hr>
                      <p class="counter_description mb-0 p-0">
                        Happy Clients
                      </p>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="counter_item">
                      <div class="counter_value">
                      <Odometer class="odometer" value={custvalue}></Odometer>
                        <span>%</span>
                      </div>
                      <hr class="bg_primary"></hr>
                      <p class="counter_description mb-0 p-0">
                       Customer Satisfaction
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="details_section section_space_sm pb-0">
        <div class="container">
          <div class="row justify-content-center">
           
            <div class="col-lg-10">
              <div class="details_content">
                <h2 class="details_item_title wow" data-splitting>
                  Our Story
                </h2>
                <p>
                  Established in 2023, <span class="carget-text">Car<span>get</span></span> has been a reliable presence in the used car industry. Our journey began with a simple vision – to redefine the way people buy used cars by prioritizing quality, honesty, and customer satisfaction. Over the years, we have grown into a dealership known for its commitment to excellence and a diverse inventory of handpicked, well-maintained vehicles.
                </p>
              </div>
              <div class="details_image">
                <img src="assets/images/about/about_image_11.jpg" alt="ProMotors - Car Repair Service"></img>
              </div>
            </div>
           
          </div>
        </div>
      </section>
    
      <section class="workprocess_section section_space_sm pb-lg-5">
        <div class="container">
          <div class="row">
            <div class="col-lg-4">
              <div class="section_heading pe-lg-5 mb-0">
                <h2 class="heading_text wow" data-splitting>Our Commitment</h2>
                <p class="heading_description mb-0">
                  Driven by Excellence, Defined by You
                </p>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-md-6 col-sm-6">
                  <div class="workprocess_item">
                    <h3 class="item_title wow" data-splitting>
                      <span class="serial_number">01</span>
                      <span class="title_text" >Quality Assurance</span>
                    </h3>
                    <p class="mb-0">
                      At <span class="carget-text"> Car<span class="red-text">Get</span></span>, every car in our inventory undergoes a rigorous inspection process to ensure it meets the highest standards of quality and safety. 
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="workprocess_item">
                    <h3 class="item_title wow" data-splitting>
                      <span class="serial_number">02</span>
                      <span class="title_text">Transparency</span>
                    </h3>
                    <p class="mb-0">
                      We believe in transparent and straightforward transactions. Our pricing is fair, and there are no hidden fees. 
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="workprocess_item">
                    <h3 class="item_title wow" data-splitting>
                      <span class="serial_number">03</span>
                      <span class="title_text">Customer Satisfaction</span>
                    </h3>
                    <p class="mb-0">
                      Our knowledgeable and friendly team is ready to assist you in finding the perfect vehicle that suits your needs and budget. 
                    </p>
                  </div>
                </div>
                <div class="col-md-6 col-sm-6">
                  <div class="workprocess_item">
                    <h3 class="item_title wow" data-splitting>
                      <span class="serial_number">04</span>
                      <span class="title_text"> Service Excellence</span>
                    </h3>
                    <p class="mb-0">
                      With a team dedicated to your satisfaction, we aim to make every interaction with us a seamless and enjoyable journey.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
     
      <section class="video_section">
        <div class="video_wrap parallaxie text-center section_space_lg" style={{  backgroundImage: 'url("assets/images/common-images/landing-parallax.jpg")'}}>
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-lg-6">
                <div class="section_heading">
                  <h2 class="heading_text wow" data-splitting>
                    Buy used cars with confidence
                  </h2>
                  <p class="heading_description mb-0 ps-lg-5 pe-lg-5">
                    At <span class="carget-text">Car<span>get</span></span>, we're not just selling cars; we're helping you embark on your next adventure with confidence. Drive with peace of mind – choose <span class="carget-text">Car<span>get</span></span> for your used car needs.
                  </p>
                </div>
                <a class="btn btn-primary" href="/details">
                  <span class="btn_text">View Our Inventory</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    

    </main>
    
      <Footer></Footer>
  

    </div>

        </>

     
    
    
  );
}

export default About;
