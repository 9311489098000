export const defaultConfig = {
 //  API_URL: 'http://localhost:9004/'
     API_URL: ' https://7w7o7o53u6.execute-api.us-east-1.amazonaws.com/dev/', // dev url
    
 };
export const App = {
  config: defaultConfig,
  services: {
    jobs: "/cargeteBridge/api/r1/getstatusData",
    jobsCount: "/cargeteBridge/api/r1/getjobCounts",
     
 
    

  },
  loginRouters: {
    loginUrlParams: "/api/auth/entry?referer=",
    logoutUrlParams: "/api/auth/logout?redirect_uri=",
  },
};




 

 
 window.env = window.env || defaultConfig;
 App.config = { ...window.env };
 
 export const API_URL = () => App.config.API_URL;
 