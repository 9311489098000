import Slider from "react-slick";
import { useEffect, useState, useRef } from "react";
import Odometer from 'react-odometerjs'
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
function Contact() {
 
  const { cargetdetails } = useSelector((state) => ({
    ...state.carsSlice,
  }));



  return (
   
     
      <>
      
 
    
  
    <div class="page_wrapper">
  
   
   <Header></Header>
      <main class="page_content">
      <section class="page_banner"
      
      style={{
        backgroundImage:
          'url("assets/images/shapes/tyre_print_3.svg")'
      }}
      
    >
          <div class="container">
            <ul class="breadcrumb_nav unordered_list">
              <li><a href="/">Home</a></li>
              <li><a href="/contact">Contact Us</a></li>
            </ul>
            <h3 class="page_title wow" data-splitting>Contact Us</h3>
          </div>
        </section>

      <section class="contact_section section_space_sm">
          <div class="container">
            <div class="row">
              <div class="col-lg-4">
                <div class="contact_info_box">
                  <h3 class="item_title flex-start"><span class="material-symbols-outlined me-3">
                    location_on
                    </span>Address</h3>
                  <ul class="info_list unordered_list_block pe-lg-2">
                    <li>
                      <span class="info_text mb-3">
                      <a
                      href= "https://maps.app.goo.gl/8jAShsoXBhecFsEv5?g_st=ic"
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cargetdetails && cargetdetails.street+ " "+ cargetdetails.city + " ," +cargetdetails.state + " " +cargetdetails.zip}
</a>
                    
                      </span>
                    </li>
                 
                  </ul>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="contact_info_box">
                  <h3 class="item_title flex-start"><span class="material-symbols-outlined me-3">
                    schedule
                    </span>Office Hours</h3>
                  <ul class="info_list unordered_list_block">
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Monday</span>
                        <span>  {cargetdetails && cargetdetails.mon}</span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Tuesday</span>
                        <span>  {cargetdetails && cargetdetails.tues}</span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Wednesday</span>
                        <span>  {cargetdetails && cargetdetails.wed}</span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Thursday</span>
                        <span>  {cargetdetails && cargetdetails.thrus}</span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Friday</span>
                        <span>  {cargetdetails && cargetdetails.fri}</span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Saturday</span>
                        <span>  {cargetdetails && cargetdetails.sat}</span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text d-flex align-items-center justify-content-between">
                        <span>Sunday</span>
                        <span>  {cargetdetails && cargetdetails.sun}</span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="col-lg-4">
                <div class="contact_info_box">
                  <h3 class="item_title flex-start"><span class="material-symbols-outlined me-3">
                    support_agent
                    </span>Customer Support</h3>
                  <ul class="info_list unordered_list_block">
                    <li>
                      <span class="info_text mb-3">
                        <span class="d-block">
                       <a href={cargetdetails && "tel:+" + cargetdetails.phone}> {cargetdetails && cargetdetails.phone}</a>
                        </span>
                      </span>
                    </li>
                    <li>
                      <span class="info_text">
                        <span class="d-block">
                        <a href={cargetdetails && "mailto:+" + cargetdetails.mail}>
                        {cargetdetails && cargetdetails.mail}
                        </a>
                        </span>
                      </span>
                    </li>
                  </ul>
                </div>
              </div>
              
              <div class="col-12">
                <div class="gmap_canvas">
                  <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2917.808741208018!2d-87.96445142437878!3d43.003361694218356!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88051a7799a17c7f%3A0x1d016e5301591881!2s3800%20W%20Lincoln%20Ave%2C%20West%20Milwaukee%2C%20WI%2053215%2C%20USA!5e0!3m2!1sen!2sin!4v1699988298777!5m2!1sen!2sin" 
                   style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
              </div>
            </div>
          </div>
        </section>
     

      </main>
    
      <Footer></Footer>
  

    </div>

        </>

     
    
    
  );
}

export default Contact;
