import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { API } from "../configurations/api";


//Initial State of theposts
const initialState = {
  serverError: false,
  errorMessage:"",
  loading:false,
  token:null,
  currentcar: {},
  cargetdetails:null,
  reviews:[]
  
};
export const setCurrentCar = createAsyncThunk(
  "dbwrklist/currentcar",
  async (data, thunkAPI) => {
    return data;
  }
);
export const getCars = createAsyncThunk('carsSlice/getCars', async (data, thunkAPI) => {

 
  let url= "api/getcars";

    return API.post(url , data, null, null);

})

export const upsertCars = createAsyncThunk('carsSlice/upsertCars', async (data, thunkAPI) => {

 
  let url= "api/upsertcars";

    return API.post(url , data, null, null);

})
export const uploads3file = createAsyncThunk(
  'carsSlice/uploads3file',
  async (data, thunkAPI) => {
   
    let url= "api/uploads3file";
 
    
    return API.post(url, data, null, null);
  }
);

export const s3getfolderfiles = createAsyncThunk(
  'carsSlice/s3getfolderfiles',
  async (data, thunkAPI) => {
  
    let url= "api/s3getfolderfiles";
 
    
    return API.post(url, data, null, null);
  }
);


export const getfilesignedurl = createAsyncThunk(
  'carsSlice/getfilesignedurl',
  async (data, thunkAPI) => {
  
    let url= "api/getfilesignedurl";
 
    
    return API.post(url, data, null, null);
  }
);


export const s3deletefile = createAsyncThunk(
  'carsSlice/s3deletefile',
  async (data, thunkAPI) => {
  
    let url= "api/s3deletefile";
 
    
    return API.post(url, data, null, null);
  }
);

export const userlogin = createAsyncThunk(
  'carsSlice/userlogin',
  async (data, thunkAPI) => {
  
    let url= "api/login";
 
    
    return API.post(url, data, null, null);
  }
);

export const upsertwebdetails = createAsyncThunk(
  'carsSlice/upsertwebdetails',
  async (data, thunkAPI) => {
  
    let url= "api/upsertwebdetails";
 
    
    return API.post(url, data, null, null);
  }
);

export const getwebdetails = createAsyncThunk(
  'carsSlice/getwebdetails',
  async (data, thunkAPI) => {
  
    let url= "api/getwebdetails";
 
    
    return API.post(url, data, null, null);
  }
);

export const sendadminemail = createAsyncThunk(
  'carsSlice/sendadminemail',
  async (data, thunkAPI) => {
  
    let url= "api/sendemail";
 
    
    return API.post(url, data, null, null);
  }
);


export const getgooglereviews = createAsyncThunk(
  'carsSlice/getgooglereviews',
  async (data, thunkAPI) => {
  

    let url= "api/getgooglereview"; 
    
    return API.post(url, data, null, null);
  }
);


const carsSlice = createSlice({
  name: "carsSlice",
  initialState,
  reducers: {
    resetError: (state) => {
      state.serverError = false;
      state.errorMessage = "";
    }
   
  },
  extraReducers: (builder) => {
    //cmrecon
    builder
    .addCase(setCurrentCar.fulfilled, (state, action) => {
      state.loading = false;
      state.currentcar = action.payload || [];
    })
      .addCase(getCars.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCars.fulfilled, (state, action) => {
        state.loading = false;
    
     
      })
      .addCase(getCars.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })


      .addCase(upsertCars.pending, (state) => {
        state.loading = true;
      })
      .addCase(upsertCars.fulfilled, (state, action) => {
        state.loading = false;
    
     
      })
      .addCase(upsertCars.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })

      .addCase(uploads3file.pending, (state) => {
        state.loading = true;
      })
      .addCase(uploads3file.fulfilled, (state, action) => {
        state.loading = false;
    
       
      })
      .addCase(uploads3file.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })
     
      .addCase(s3getfolderfiles.pending, (state) => {
        state.loading = true;
      })
      .addCase(s3getfolderfiles.fulfilled, (state, action) => {
        state.loading = false;
    
      })
      .addCase(s3getfolderfiles.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })

         
      .addCase(getfilesignedurl.pending, (state) => {
        state.loading = true;
      })
      .addCase(getfilesignedurl.fulfilled, (state, action) => {
        state.loading = false;
    
      })
      .addCase(getfilesignedurl.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })


      .addCase(s3deletefile.pending, (state) => {
        state.loading = true;
      })
      .addCase(s3deletefile.fulfilled, (state, action) => {
        state.loading = false;
    
      })
      .addCase(s3deletefile.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })

      
      .addCase(userlogin.pending, (state) => {
        state.loading = true;
      })
      .addCase(userlogin.fulfilled, (state, action) => {
        
        state.loading = false;
        state.token = action.payload?.data?.result?.token || null;
    
      })
      .addCase(userlogin.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })

      
      
      .addCase(getwebdetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(getwebdetails.fulfilled, (state, action) => {
        
        state.loading = false;
        state.cargetdetails=action?.payload?.data?.result||null;
    
      })
      .addCase(getwebdetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })
      

      .addCase(upsertwebdetails.pending, (state) => {
        state.loading = true;
      })
      .addCase(upsertwebdetails.fulfilled, (state, action) => {
        
        state.loading = false;
      
    
      })
      .addCase(upsertwebdetails.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })

      .addCase(sendadminemail.pending, (state) => {
        state.loading = true;
      })
      .addCase(sendadminemail.fulfilled, (state, action) => {
        
        state.loading = false;
      
    
      })
      .addCase(sendadminemail.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })
      

      .addCase(getgooglereviews.pending, (state) => {
        state.loading = true;
      })
      .addCase(getgooglereviews.fulfilled, (state, action) => {
        state.reviews = action.payload?.data?.result?.reviews || null;
        state.loading = false;
      
    
      })
      .addCase(getgooglereviews.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = "";
        throw new Error(action.error.message);
      })
      
      
      
   
  }


});


export default carsSlice.reducer;
