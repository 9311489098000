import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getwebdetails } from "../services/carsSlice";

function Footer() {
  const { cargetdetails } = useSelector((state) => ({
    ...state.carsSlice,
  }));
  return (
    <footer className="site_footer">
      <div className="footer_content_area section_space_lg bg_gray_dark">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-6">
              <div className="footer_about pe-lg-5">
                <div className="site_logo">
                  <img
                    className="footer_logo"
                    src="assets/images/site_logo/cartget_logo_full_white.svg"
                    alt="Site Logo"
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="info_list_wrap">
                <h3 className="list_title">Navigation</h3>
                <div className="row">
                  <div className="col-md-12 col-sm-12">
                    <ul className="info_list unordered_list_block text-uppercase footer-nav">
                      <li>
                        <a href="/">
                          <span className="info_icon">
                            <img
                              src="assets/images/icons/icon_square.svg"
                              alt="Icon Square"
                            />
                          </span>
                          <span className="info_text">Home</span>
                        </a>
                      </li>
                      <li>
                        <a href="/details">
                          <span className="info_icon">
                            <img
                              src="assets/images/icons/icon_square.svg"
                              alt="Icon Square"
                            />
                          </span>
                          <span className="info_text">Inventory</span>
                        </a>
                      </li>
                      <li>
                        <a
                          href={cargetdetails && cargetdetails.finance}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <span className="info_icon">
                            <img
                              src="assets/images/icons/icon_square.svg"
                              alt="Icon Square"
                            />
                          </span>
                          <span className="info_text">Financing</span>
                        </a>
                      </li>
                      <li>
                        <a href="/about">
                          <span className="info_icon">
                            <img
                              src="assets/images/icons/icon_square.svg"
                              alt="ProMotors - Icon Square"
                            />
                          </span>
                          <span className="info_text">About Us</span>
                        </a>
                      </li>
                      <li>
                        <a href="/contact">
                          <span className="info_icon">
                            <img
                              src="assets/images/icons/icon_square.svg"
                              alt="Icon Square"
                            />
                          </span>
                          <span className="info_text">Contact Us</span>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3">
              <div className="footer_subscribe_form">
                <h3 className="list_title">get intouch</h3>
                <ul className="contact-list">
                  <li>
                    <span className="material-symbols-outlined"> call </span>

                    <a href={cargetdetails && "tel:+" + cargetdetails.phone}>
                      {cargetdetails && cargetdetails.phone}
                    </a>
                  </li>
                  <li>
                    <span className="material-symbols-outlined"> email </span>

                    <a href={cargetdetails && "mailto:+" + cargetdetails.mail}>
                      {cargetdetails && cargetdetails.mail}
                    </a>
                  </li>
                  <li>
                    <span className="material-symbols-outlined">
                      location_on
                    </span>
                    <a
                      href={
                        cargetdetails &&
                        "https://maps.app.goo.gl/8jAShsoXBhecFsEv5?g_st=ic"
                      }
                      target="_blank"
                      rel="noreferrer"
                    >
                      {cargetdetails && cargetdetails.street}
                      <br />
                      {cargetdetails &&
                        cargetdetails.city +
                          " ," +
                          cargetdetails.state +
                          " " +
                          cargetdetails.zip}
                    </a>
                  </li>
                </ul>
                <ul className="header-socialmedia mt-4 ms-3">
                  <li>
                    <a
                      href={cargetdetails && cargetdetails.facebook}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="assets/images/social-media/fb.png" />
                    </a>
                  </li>
                  <li>
                    <a
                      href={cargetdetails && cargetdetails.instagram}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src="assets/images/social-media/insta.png" />
                    </a>
                  </li>
                  {/* <li>
                    <a href={cargetdetails && cargetdetails.twitter} target="_blank" rel="noreferrer">
                      <img src="assets/images/social-media/twitter.png" />
                    </a>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright_widget">
        <div className="container">
          <p className="copyright_text text-center mb-0">
            ©{" "}
            <b>
              <span className="carget-text">
                {" "}
                Car<span className="red-text">Get</span>
              </span>
            </b>{" "}
            All rights reserved 2023
          </p>
        </div>
      </div>
    </footer>
  );
}
export default Footer;
