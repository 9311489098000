import Slider from "react-slick";
import { useEffect, useState, useRef, useReducer } from "react";
import Odometer from "react-odometerjs";
import Header from "../components/Header";
import Footer from "../components/Footer";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { SnackbarProvider, VariantType, useSnackbar } from "notistack";

import {
  getCars,
  s3getfolderfiles,
  sendadminemail,
} from "../services/carsSlice";
import _ from "lodash";
function InvDetails() {
  const { enqueueSnackbar } = useSnackbar();
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);
  const [carimagesdata, setcarimagesdata] = useState([]);

  const [item, setcardata] = useState(null);
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  // const { cargetdetails } = useSelector((state) => ({
  //   ...state.carsSlice,
  // }));
  const handleOpen = () => {
    setOpen(true);
  };
  const search = useLocation().search;
  const vin = new URLSearchParams(search).get("vin");
  const apidispatch = useDispatch();
  const reducer = (state, { field, value }) => {
    return { ...state, [field]: value };
  };
  const [formState, dispatch] = useReducer(
    reducer,
    {
      fname: "",
      lname: "",
      email: "",
      phone: "",
      message: "",
    },
    undefined
  );

  const { fname, lname, email, phone, message } = formState;

  const handleInput = (e) => {
    dispatch({
      field: Object.keys(e.target.dataset)[0],
      value: e.target.value,
    });
  };

  const handleSubmit = (e, item) => {
    setOpen(true);
    var data = JSON.parse(JSON.stringify(formState));
    data.car = item;

    if (phone && fname && email) {
      apidispatch(sendadminemail(data))
        .then(async (values) => {
          let result = values?.payload?.data?.result;
          setOpen(false);
          enqueueSnackbar(
            "Thank You For Contacting Us. We will Get Back To You"
          );
          document.getElementById("eform").reset();
          document.getElementById("closeModal").click();
        })
        .catch((error) => {});
    } else {
      e.preventDefault();
      enqueueSnackbar("Thank You For Contacting Us. We will Get Back To You");
      setOpen(false);
    }
  };

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  });

  useEffect(() => {
    if (carimagesdata) {
      getCardata();
      getCarsImgList();
    }
  }, [vin]);

  const getCardata = () => {
    apidispatch(getCars({ vin: vin }))
      .then((values) => {
        let result = values?.payload?.data?.result;

        setcardata(result);
      })
      .catch((error) => {});
  };

  const getCarsImgList = async () => {
    setOpen(true);
    apidispatch(s3getfolderfiles({ vin: vin }))
      .then(async (values) => {
        let result = values?.payload?.data?.result;

        setcarimagesdata(result);

        setOpen(false);
      })
      .catch((error) => {});
  };

  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: ".slider-for",
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const settingsThumbs = {
    slidesToShow: 3,
    slidesToScroll: 1,
    asNavFor: ".slider-nav",
    dots: false,
    arrows: true,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: "10px",
    autoplay: true,
    autoplaySpeed: 5000,
  };

  const slidesData = [
    {
      id: 1,
      title: "repellendus id ullam",
      label: "Dolorem officiis temporibus.",
    },
    {
      id: 2,
      title: "excepturi consequatur est",
      label: "Officia non provident dolor esse et neque.",
    },
    {
      id: 3,
      title: "eius doloribus blanditiis",
      label: "Ut recusandae vel vitae molestiae id soluta.",
    },
    {
      id: 4,
      title: "nihil voluptates delectus",
      label: "Qui vel consequatur recusandae illo repellendus.",
    },
    {
      id: 5,
      title: "nemo dolorem necessitatibus",
      label: "Placeat odit velit itaque voluptatem.",
    },
    {
      id: 6,
      title: "dolorem quibusdam quasi",
      label: "Adipisci officiis repudiandae.",
    },
    {
      id: 7,
      title: "dolorem quibusdam quasi",
      label: "Adipisci officiis repudiandae.",
    },
    {
      id: 8,
      title: "dolorem quibusdam quasi",
      label: "Adipisci officiis repudiandae.",
    },
    {
      id: 9,
      title: "dolorem quibusdam quasi",
      label: "Adipisci officiis repudiandae.",
    },
    {
      id: 10,
      title: "dolorem quibusdam quasi",
      label: "Adipisci officiis repudiandae.",
    },
  ];

  return (
    <>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <div class="modal cargetModal" id="enquireModal">
        <form
          id="eform"
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit(e, item);
          }}
        >
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h4 class="modal-title">Enquire</h4>
                <button
                  type="button"
                  class="btn-close"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <div class="modal-body">
                <p class="mb-4">
                  I am interested in:{" "}
                  <b>
                    {" "}
                    {item && item.year + " " + item.make + " " + item.model}
                  </b>
                </p>
                <div class="form_wrap row">
                  <div class="col-md-6">
                    <div class="form-group mb-0">
                      <label for="input_name">First Name</label>
                      <input
                        type="text"
                        onInput={handleInput}
                        data-fname
                        class="form-control"
                        placeholder="Enter First Name"
                        required
                      ></input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-0">
                      <label for="input_phone">last Name</label>
                      <input
                        type="text"
                        onInput={handleInput}
                        data-lname
                        class="form-control"
                        placeholder="Enter Last Name"
                        required
                      ></input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-0">
                      <label for="input_phone">E Mail</label>
                      <input
                        type="text"
                        onInput={handleInput}
                        data-email
                        class="form-control"
                        placeholder="Enter Email Id"
                        required
                      ></input>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group mb-0">
                      <label for="input_phone">Contact Number</label>
                      <input
                        type="text"
                        onInput={handleInput}
                        data-phone
                        class="form-control"
                        placeholder="Enter Phone Number"
                        required
                      ></input>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group">
                      <label for="input_textarea">Your Message</label>
                      <textarea
                        data-message
                        onInput={handleInput}
                        class="form-control"
                        id="input_textarea"
                        placeholder="Tell us More"
                      ></textarea>
                    </div>
                  </div>
                </div>
              </div>

              <div class="modal-footer">
                <div class="flex-spread">
                  <a
                    class="btn-link mr-3"
                    href="#"
                    data-bs-toggle="modal"
                    data-bs-target="#enquireModal"
                  >
                    <span class="btn_icon">
                      <i class="fa-regular fa-angle-right"></i>
                    </span>
                    <span class="btn_text" data-bs-dismiss="modal">
                      <small>Cancel</small>
                      <small>Cancel</small>
                    </span>
                  </a>
                  <button type="submit" class="btn btn-danger">
                    Enquire
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>

      <div class="page_wrapper">
        <Header></Header>
        <div class="container">
          <ul class="breadcrumb_nav unordered_list">
            <li>
              <a href="/">Home</a>
            </li>
            <li>
              <a href="/details">Inventory</a>
            </li>
          </ul>
        </div>
        <main class="page_content">
          <div class="container">
            <h2 class="details-header-title ">
              {item && item.year + " " + item.make + " " + item.model+ " " + item.trim}
            </h2>

            <div class="row">
              <div class="col-md-8 rel-pos">
                <div className="detail-slider">
                  <Slider
                    {...settingsMain}
                    asNavFor={nav2}
                    ref={(slider) => setSlider1(slider)}
                  >
                    {carimagesdata.map((slide, index) => (
                      <div
                        className="slider-1 slider-for slick-slide"
                        key={index}
                      >
                        <img className="slick-slide-image" src={slide.s3url} />
                      </div>
                    ))}
                  </Slider>
                  <div className="thumbnail-slider-wrap">
                    <Slider
                      {...settingsThumbs}
                      asNavFor={nav1}
                      ref={(slider) => setSlider2(slider)}
                    >
                      {carimagesdata.map((slide, index) => (
                        <div className="slick-slide">
                          <img
                            key={index}
                            className="slick-slide-image"
                            src={slide.s3url}
                          />
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
{/* 
              <div class="col-md-4 rel-pos">
                <h5 class="car-price mb-3 mt-3 ">
                  {" "}
                  {item && "$" + item.price}
                </h5>

                <ul class=" unordered_list_block features_list ">
                  <li>
                    <span class="material-symbols-outlined">description</span>
                    {item && "VIN : " + item.vin}
                  </li>
                  <li>
                    <span class="material-symbols-outlined">
                      directions_car
                    </span>
                    {item && "Type : " + item.cartype}
                  </li>
                  <li>
                    <span class="material-symbols-outlined">monitor_heart</span>
                    {item && "Engine : " + item.engine}
                  </li>
                  <li>
                    <span class="material-symbols-outlined">
                      local_shipping
                    </span>
                    {item && "WheelDrive : " + item.wheeldrive}
                  </li>
                  <li>
                    <span class="material-symbols-outlined">card_travel</span>
                    {item && "Miles : " + item.miles}
                  </li>
                  <li>
                    <span class="material-symbols-outlined">
                      history_toggle_off
                    </span>
                    {item && "Year : " + item.year}
                  </li>
                  <li>
                    <span class="material-symbols-outlined">group</span>
                    {item && "Owners : " + item.numberofowners}
                  </li>
                </ul>
                <button
                  type="submit"
                  class="btn btn-primary mt-3"
                  href="#"
                  data-bs-toggle="modal"
                  data-bs-target="#enquireModal"
                >
                  <span class="btn_text">Enquire</span>
                </button>

                <div class="about_content">
                  <div class="section_heading">
                    <div class="workprocess_item">
                      <h3 class="item_title wow" data-splitting></h3>
                    </div>
                  </div>{" "}
                </div>
                <section class="video_section">
                  <div class="text-center section_space_lg">
                    <div class="container">
                      <div class="row justify-content-center">
                        <a
                          class="btn btn-primary"
                          target="_blank"
                          rel="noreferrer"
                          href="https://marinecreditunion.my.site.com/REG/s/registration?PartnerCode=0013i00000GZdfd"
                        >
                          <span class="btn_text">We Finance</span>
                        </a>
                      </div>
                    </div>
                  </div>
                </section>
              </div> */}



{/* <div class="col-md-4 rel-pos">
        <h5 class="car-price mb-3">{item && "$ " + item.price}</h5>
        <div class="Specifications">
          <ul class="additional_info_list unordered_list_block">
            <li>
              <span><img src="assets/images/icons/VIN.png" class="spec-icons me-3"/>VIN</span>
              <span>      {item &&  item.vin}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/exterior.png" class="spec-icons me-3"/>Type</span>
              <span>{item &&  item.cartype}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/engine.png" class="spec-icons me-3"/>Engine</span>
              <span>{item &&  item.engine}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/drive.png" class="spec-icons me-3"/>Wheel Drive</span>
              <span>{item &&  item.wheeldrive}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/fuel.png" class="spec-icons me-3"/>Miles</span>
              <span>{item &&  item.miles}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/year.png" class="spec-icons me-3"/>Year</span>
              <span>{item &&  item.year}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/owner.png" class="spec-icons me-3"/>Owners</span>
              <span>{item &&  item.numberofowners}</span>
            </li>
          </ul>
        </div> 
        <button type="submit" class="btn btn-primary mt-3" href="#" data-bs-toggle="modal" data-bs-target="#enquireModal">
          <span class="btn_text">Enquire</span>
      </button>
      <h5 class="mt-5"><span>Need a car loan? </span>We can help you get the best deal.</h5>
      <p class="heading_description mb-0">Don’t let your dream car slip away. Apply for car finance support today and get behind the wheel in no time</p>
      <a class="btn-link mt-4" href="https://marinecreditunion.my.site.com/REG/s/registration?PartnerCode=0013i00000GZdfd"><span class="btn_icon"><i class="fa-regular fa-angle-right"></i></span><span class="btn_text"><small>Start Finance Process</small><small>Start Finance Process</small></span></a>
      </div> */}

      <div class="col-md-4 rel-pos">
        <h5 class="car-price mb-3">{item && "$ " + item.price}</h5>
        <div class="Specifications">
          <ul class="additional_info_list unordered_list_block">
            <li>
              <span><img src="assets/images/icons/VIN.png" class="spec-icons me-3"/>VIN</span>
              <span>      {item &&  item.vin}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/owner.png" class="spec-icons me-3"/>Owners</span>
              <span>      {item &&  item.numberofowners}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/owner.png" class="spec-icons me-3"/>Transmission</span>
              <span>      {item &&  item.transmission}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/engine.png" class="spec-icons me-3"/>Engine</span>
              <span>      {item &&  item.engine}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/drive.png" class="spec-icons me-3"/>Drive</span>
              <span>      {item &&  item.wheeldrive}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/fuel.png" class="spec-icons me-3"/>Miles</span>
              <span>      {item &&  item.miles}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/year.png" class="spec-icons me-3"/>Exterior</span>
              <span>      {item &&  item.exterior}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/year.png" class="spec-icons me-3"/>Interior</span>
              <span>      {item &&  item.interior}</span>
            </li>
            <li>
              <span><img src="assets/images/icons/year.png" class="spec-icons me-3"/>Stock no</span>
              <span>      {item &&  item.stockno}</span>
            </li>
          </ul>
        </div> 
        <button type="submit" class="btn btn-primary mt-3" href="#" data-bs-toggle="modal" data-bs-target="#enquireModal">
          <span class="btn_text">Enquire</span>
      </button>
      <h5 class="mt-5"><span class="red-text">Need a car loan? </span></h5>
      <p class="heading_description mb-0">Don’t let your dream car slip away. Apply for car finance support today and get behind the wheel in no time</p>
      <a class="btn-link mt-4" href="https://marinecreditunion.my.site.com/REG/s/registration?PartnerCode=0013i00000GZdfd"><span class="btn_icon"><i class="fa-regular fa-angle-right"></i></span><span class="btn_text"><small>Start Finance Process</small><small>Start Finance Process</small></span></a>
      </div>



            </div>
          </div>

          <div class="container mt-lg-5">
            <div class="product_additional_info">
              <ul class="nav tab_nav style_3" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="active"
                    data-bs-toggle="tab"
                    data-bs-target="#tab_description"
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Features
                  </button>
                </li>

                {/* <li class="nav-item" role="presentation">
              <button data-bs-toggle="tab" data-bs-target="#tab_reviews" type="button" role="tab" aria-selected="false">
                Reviews
              </button>
            </li> */}
              </ul>
              <div class="tab-content p-0 bg-transparent">
                <div
                  class="tab-pane fade show active"
                  id="tab_description"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class=" unordered_list_block features_list ">
                            {item &&
                              item?.features.length !== 0 &&
                              item.features
                                .split("\n")
                                .map((feature, index) => {
                                  if (index % 2 === 0) {
                                    return (
                                      <li key={index}>
                                        <span class="material-symbols-outlined">
                                          done
                                        </span>
                                        {feature}
                                      </li>
                                    );
                                  }
                                })}
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class=" unordered_list_block features_list ">
                            {item &&
                              item?.features.length !== 0 &&
                              item.features
                                .split("\n")
                                .map((feature, index) => {
                                  if (index % 2 !== 0) {
                                    return (
                                      <li key={index}>
                                        <span class="material-symbols-outlined">
                                          done
                                        </span>
                                        {feature}
                                      </li>
                                    );
                                  }
                                })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="p-0 mt-5">{item && item.description}</p>
                </div>

                {/* <div class="tab-pane fade" id="tab_reviews" role="tabpanel">
              <div class="review_form">
                <form action="#">
                  <div class="form-group">
                    <label>Your Name</label>
                    <ul class="rating_star unordered_list">
                      <li><a href="#!"><i class="fas fa-star"></i></a></li>
                      <li><a href="#!"><i class="fas fa-star"></i></a></li>
                      <li><a href="#!"><i class="fas fa-star"></i></a></li>
                      <li><a href="#!"><i class="fas fa-star"></i></a></li>
                      <li><a href="#!"><i class="fas fa-star"></i></a></li>
                    </ul>
                  </div>
                  <div class="row mb-4">
                    <div class="col-12">
                      <div class="form-group mb-0">
                        <label for="input_comment">Your Comment</label>
                        <textarea name="comment" class="form-control" id="input_comment" placeholder="Write Your Comment"></textarea>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="input_name">Your Name</label>
                        <input type="text" name="name" class="form-control" id="input_name" placeholder="Enter Your Name"></input>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="form-group mb-0">
                        <label for="input_email_2">Your Email</label>
                        <input type="email" name="email" class="form-control" id="input_email_2" placeholder="Enter Your Email"></input>
                      </div>
                    </div>
                  </div>
                  <button type="submit" class="btn btn-primary">
                    <span class="btn_text">Submit Review</span>
                  </button>
                </form>
              </div>
            </div> */}
              </div>
            </div>
          </div>
          <div class="container mt-lg-5">
            <div class="product_additional_info">
              <ul class="nav tab_nav style_3" role="tablist">
                <li class="nav-item" role="presentation">
                  <button
                    class="active"
                    data-bs-toggle="tab"
                    data-bs-target="#tab_description"
                    type="button"
                    role="tab"
                    aria-selected="true"
                  >
                    Product Description
                  </button>
                </li>

                {/* <li class="nav-item" role="presentation">
              <button data-bs-toggle="tab" data-bs-target="#tab_reviews" type="button" role="tab" aria-selected="false">
                Reviews
              </button>
            </li> */}
              </ul>
              <div class="tab-content p-0 bg-transparent">
                <div
                  class="tab-pane fade show active"
                  id="tab_description"
                  role="tabpanel"
                >
                  <div class="row">
                    <div class="col-md-12">
                      <div class="row">
                        <div class="col-md-6">
                          <ul class=" unordered_list_block features_list ">
                            {item &&
                              item?.proddesc.length !== 0 &&
                              item.proddesc
                                .split("\n")
                                .map((feature, index) => {
                                  if (index % 2 === 0) {
                                    return (
                                      <li key={index}>
                                        <span class="material-symbols-outlined">
                                          done
                                        </span>
                                        {feature}
                                      </li>
                                    );
                                  }
                                })}
                          </ul>
                        </div>
                        <div class="col-md-6">
                          <ul class=" unordered_list_block features_list ">
                            {item &&
                              item?.proddesc.length !== 0 &&
                              item.proddesc
                                .split("\n")
                                .map((feature, index) => {
                                  if (index % 2 !== 0) {
                                    return (
                                      <li key={index}>
                                        <span class="material-symbols-outlined">
                                          done
                                        </span>
                                        {feature}
                                      </li>
                                    );
                                  }
                                })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                  <p class="p-0 mt-5">{item && item.description}</p>
                </div>
              </div>
            </div>
          </div>
        </main>

        <Footer></Footer>
      </div>
    </>
  );
}

export default InvDetails;
