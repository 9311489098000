
export const saveLocalStorageHelper = (key,user) => {
    
    localStorage.setItem(key,JSON.stringify(user))
    return user;
}

export const getLocalStorageHelper = (key) => {
    
    try{
        return JSON.parse(localStorage.getItem(key));

    }catch(err){
        return localStorage.getItem(key);

    }
}
export const updateTokenIntoLocalStorageHelper = (key,token) => {

    if( token && token.length){
     var user = getLocalStorageHelper(key)
     user["accessToken"] = token;
     saveLocalStorageHelper(key,user)

     return getLocalStorageHelper(key);

 
    }
 }

