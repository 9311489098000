import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
//Define All reducerLevel Slices



import carsSliceReducer from "../../services/carsSlice"; 
/*Configure Store with Multiple Slices*/

export default configureStore({
  reducer: combineReducers({

   
 
    carsSlice:carsSliceReducer
  }),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});
