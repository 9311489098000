import Main from "./pages/Main";
import Inventory from "./pages/Inventory";
import Contact from "./pages/Contact";
import About from "./pages/About";
import InvDetails from './pages/InvDetails'
import {
  BrowserRouter as Router, Route, Routes,
  useLocation,
  Navigate
} from 'react-router-dom';

function App() {
 
  return (
   
    <Router>
    <Routes>
      <Route exact path='/' element={<Main />} />
      <Route exact path='/details' element={<Inventory />} />
      <Route exact path='/contact' element={<Contact />} />
      <Route exact path='/about' element={<About />} />
      <Route exact path='/invdetails' element={<InvDetails />} />
    </Routes>
  </Router>
    

     
    
    
  );
}

export default App;
