import React, { useState, useEffect, useRef, useReducer } from "react";
import { Link, Redirect } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getwebdetails } from "../services/carsSlice";
import { useLocation } from "react-router-dom";
function Header() {
  const apidispatch = useDispatch();
  const location = useLocation();

  const { cargetdetails } = useSelector((state) => ({
    ...state.carsSlice,
  }));
  useEffect(() => {
    if (!cargetdetails) {
      getContactInfo();
    }
  }, [cargetdetails]);

  const getContactInfo = () => {
    apidispatch(getwebdetails())
      .then((values) => {
        let result = values?.payload?.data?.result;
      })
      .catch((error) => {});
  };

  const topFunction = () => {
 
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  return (
    <>
      <div class="backtotop">
        <a href=""  class="scroll">
          <i class="fa-solid fa-arrow-up" onClick={topFunction}></i>
        </a>
      </div>
      <div className="top-header">
        <div className="container">
          <div className="banner-block">
            <div className="alert-marquee-block">
              {/* <div className="alert-marquee-title me-2"> Support 24/7: </div> */}
              <div className="alert-marquee-content">
                <ul>
                  <li>
                    {/* <span className="carget-text">
                      {" "}
                      Car<span className="red-text">Get</span>
                    </span>{" "} */}{" "}
                    <a href={cargetdetails && "tel:+" + cargetdetails.phone}>
                      {cargetdetails && cargetdetails.topoffer}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="float-end support-text">
              <ul className="header-socialmedia">
                <li>
                  <a
                    href={cargetdetails && cargetdetails.facebook}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/images/social-media/fb.png" />
                  </a>
                </li>
                <li>
                  <a
                    href={cargetdetails && cargetdetails.instagram}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src="assets/images/social-media/insta.png" />
                  </a>
                </li>
                {/* <li>
                  <a href={cargetdetails && cargetdetails.twitter} target="_blank" rel="noreferrer">
                    <img src="assets/images/social-media/twitter.png" />
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <header className="site_header">
        <div className="header_bottom">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-3 col-5">
                <div className="site_logo">
                  <a className="site_link" href="/">
                    <img
                      className="header_logo"
                      src="assets/images/site_logo/carget_logo_text.svg"
                      alt="Site Logo"
                    />
                  </a>
                </div>
              </div>
              <div className="col-lg-6 col-2">
                <nav className="main_menu navbar navbar-expand-lg">
                  <div
                    className="main_menu_inner collapse navbar-collapse justify-content-center"
                    id="main_menu_dropdown"
                  >
                    <ul className="main_menu_list unordered_list_center">
                      <li className={location.pathname === "/" ? "active" : ""}>
                        <a className="nav-link" href="/">
                          Home
                        </a>
                      </li>
                      <li
                        className={
                          location.pathname === ("/details"||"/invdetails") ? "active" : ""
                        }
                      >
                        <a href="/details">Inventory</a>
                      </li>
                      <li>
                        <a
                          href={cargetdetails && cargetdetails.finance}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Financing
                        </a>
                      </li>
                      <li
                        className={
                          location.pathname === "/about" ? "active" : ""
                        }
                      >
                        <a href="/about">About Us</a>
                      </li>
                      <li
                        className={
                          location.pathname === "/contact" ? "active" : ""
                        }
                      >
                        <a href="/contact">Contact Us</a>
                      </li>
                    </ul>
                  </div>
                </nav>
              </div>
              <div className="col-lg-3 col-5">
                <ul className="header_btns_group unordered_list_end">
                  <li>
                    <button
                      className="mobile_menu_btn"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#main_menu_dropdown"
                      aria-expanded="false"
                      aria-label="Toggle navigation"
                    >
                      <i className="far fa-bars" />
                    </button>
                  </li>
                  <li>
                    <a className="btn_hotline" href="javacript:void(0);">
                      <span
                        className="btn_text"
                        data-text={
                          cargetdetails &&
                           cargetdetails.offer
                        }
                      >
                      {cargetdetails && cargetdetails.offer}
                      </span>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
export default Header;
